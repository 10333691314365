import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import baublesList from "const/baublesList";
import { setTextId } from "./textIdSlice";

const generateUUID = () => {
  let d = new Date().getTime();
  const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
};

const newText = (id) => {
  return {
    id: id,
    size: 120,
    color: "#000000",
    font: "Open Sans",
    content: "",
    position: { x: 0, y: 0 },
    bold: false,
    italic: false,
    underline: false,
    align: "left",
    stroke: { color: "#ffffff", width: 0 },
  };
};

let storedSettings = localStorage.getItem("appSettings");

if (storedSettings) {
  try {
    storedSettings = JSON.parse(storedSettings);
  } catch (error) {
    console.error("Invalid JSON in localStorage:", error);
    storedSettings = null;
  }
}

if (!storedSettings) {
  storedSettings = baublesList[0];
  localStorage.setItem("appSettings", JSON.stringify(storedSettings));
}

const initialState = {
  settings: storedSettings,
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setSettings(state, action) {
      localStorage.setItem("appSettings", JSON.stringify(action.payload));
      state.settings = action.payload;
    },
    setBaubleColor(state, action) {
      state.settings.baubleColor = action.payload;
      localStorage.setItem("appSettings", JSON.stringify(state.settings));
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setTextArea.fulfilled, (state, action) => {
      localStorage.setItem("appSettings", JSON.stringify(action.payload));
      state.settings = action.payload;
    });
  },
});

export const setTextArea = createAsyncThunk("settings/setTextArea", async (_, thunkAPI) => {
  const state = thunkAPI.getState();
  const settings = state.settings.settings;
  const printAreaId = state.printArea.printArea.id;
  const newId = generateUUID();
  const newTextArray = newText(newId);

  const printAreas = settings.printAreas.map((area) =>
    area.id === printAreaId ? { ...area, texts: area.texts.concat(newTextArray) } : area
  );

  const newSettings = {
    ...settings,
    printAreas,
  };

  thunkAPI.dispatch(setTextId(newId));

  return newSettings;
});

export const { setSettings, setBaubleColor } = settingsSlice.actions;

export default settingsSlice.reducer;
