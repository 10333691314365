import translations from "const/translations.json";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useExportBase64Image } from "hooks/useExportBase64Image";
export const OfferRequest = ({ lang, setAlert,alert, setOfferModal, endpoint = "/" }) => {
  const settings = useSelector((state) => state.settings.settings);
  const { getImageBase64 } = useExportBase64Image();
  const [sending, setSending] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setSending(true);
    const imageBase64 = await getImageBase64();

    fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: e.target.email.value,
        message: e.target.message.value,
        settings: settings,
        imageBase64: imageBase64,
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          setAlert({open:true,type:"success"})
          return response.json();
        } else {
          setSending(false);
          setAlert({open:true,type:"error"})
          console.error(response);
        }
      })
      .then(() => {
        setSending(false);
        setTimeout(()=>{
          setAlert({...alert,open:false})
        },5000)
        setOfferModal(false);
      })
      .catch((error) => {
        setAlert({open:true,type:"error"})
        setSending(false);
        console.error("Error:", error);
      }).finally(()=>{
      setTimeout(()=>{
        setAlert({...alert,open:false})
      },5000)
    })
  };

  return (
    <div className="w-screen h-screen left-0 top-0 z-[190000] min absolute bg-[#0000004D] flex items-center justify-center">
      <div className=" px-[32px] pt-[20px] pb-[32px] max-w-[464px] w-full max-h-[434px] flex flex-col h-full bg-white rounded-[10px]">
        <button
          onClick={() => {
            setOfferModal(false);
          }}
          className="mb-[10px] self-end"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <path
              d="M17.414 16L24 9.414L22.586 8L16 14.586L9.414 8L8 9.414L14.586 16L8 22.586L9.414 24L16 17.414L22.586 24L24 22.586L17.414 16Z"
              fill="#333333"
            />
          </svg>
        </button>
        <form className="flex flex-col h-full" onSubmit={handleSubmit}>
          <div className="flex flex-col ">
            <label htmlFor="email">{translations[lang].offer.emailLabel}</label>
            <input
              type="email"
              name="email"
              id="email"
              placeholder={translations[lang].offer.emailPlaceholder}
              className="mt-[8px] px-[16px] py-[20px] border-[1px] border-[#E0E0E0] rounded-[5px]"
            />
          </div>
          <div className="mt-[24px] flex flex-col ">
            <label htmlFor="message">{translations[lang].offer.messageLabel}</label>
            <textarea
              type="email"
              name="message"
              id="message"
              placeholder={translations[lang].offer.messagePlaceholder}
              className="resize-none h-[120px] mt-[8px] px-[16px] py-[20px] border-[1px] border-[#E0E0E0] rounded-[5px]"
            />
          </div>
          <button
            className="rounded-[5px] mt-[24px] text-white bg-[#2EA3F2] w-full py-[15px] px-[16px] disabled:opacity-50"
            type="submit"
            disabled={sending}
          >
            {translations[lang].offer.button} {sending && "..."}
          </button>
        </form>
      </div>
    </div>
  );
};
