import { useSelector, shallowEqual } from "react-redux";
import baublesList from "const/baublesList";
import { useEffect, useState, useContext } from "react";
import TextureUpdateContext from "@/context/TextureUpdateContext";

const imageCache = {};

const loadImage = (src) => {
  return new Promise((resolve, reject) => {
    if (imageCache[src]) {
      resolve(imageCache[src]);
    } else {
      const img = new Image();
      img.onload = () => {
        imageCache[src] = img;
        resolve(img);
      };
      img.onerror = reject;
      img.src = src;
    }
  });
};

const InvisibleCanvas = ({ canvasRef }) => {
  const bauble = useSelector((state) => state.bauble.bauble, shallowEqual);
  const settings = useSelector((state) => state.settings.settings, shallowEqual);

  const availableAreasIds = baublesList.find((_bauble) => _bauble.id === bauble.id).printAreas.map((area) => area.id);

  const filteredSettingsAreas = settings.printAreas.filter((printAreaSetting) =>
    availableAreasIds.includes(printAreaSetting.id)
  );

  const [imgFile, setImgFile] = useState(null);
  const { updateTexture } = useContext(TextureUpdateContext);

  const handleRenderingDone = () => {
    updateTexture();
  };

  useEffect(() => {
    async function setImgFileState() {
      const imgSrc = filteredSettingsAreas[0].image.src;

      try {
        const imgFile = await loadImage(imgSrc);
        setImgFile(imgFile);
      } catch (e) {
        setImgFile(null);
      }
    }

    if (filteredSettingsAreas.length > 0) {
      setImgFileState();
    }
  }, [settings]);

  useEffect(() => {
    if (!canvasRef.current) return;
    if (bauble.name !== settings.name) {
      return;
    }
    const ctx = canvasRef.current.getContext("2d");
    canvasRef.current.width = settings.canvasSize.width;
    canvasRef.current.height = settings.canvasSize.height;

    ctx.setTransform(1, 0, 0, 1, 0, 0);
    ctx.fillStyle = settings.baubleColor;
    ctx.fillRect(0, 0, settings.canvasSize.width, settings.canvasSize.height);

    filteredSettingsAreas.map((printAreaSetting) => {
      const { image } = printAreaSetting;

      if (imgFile) {
        drawImage(ctx, printAreaSetting, image, imgFile);
      }

      if (printAreaSetting.texts?.length > 0) {
        drawTexts(ctx, printAreaSetting);
      }
    });

    handleRenderingDone();
  }, [filteredSettingsAreas, settings, bauble, canvasRef, imgFile]);

  return null;
  // return <canvas ref={canvasRef} />;
};

export default InvisibleCanvas;

const drawTexts = (ctx, printAreaSetting) => {
  const textCanvas = document.createElement("canvas");
  textCanvas.width = printAreaSetting.setting.size.width;
  textCanvas.height = printAreaSetting.setting.size.height;
  const textCtx = textCanvas.getContext("2d");

  const { texts } = printAreaSetting;

  if (!texts) return;

  texts.forEach((text) => {
    const { position, content, size, font, color, align, bold, italic, stroke, underline } = text;
    let maxWidth = 0;
    let fontStyle = `${italic ? "italic" : ""} ${bold ? "bold" : ""}`;

    textCtx.font = `${fontStyle} ${size}px ${font}`;
    textCtx.imageSmoothingEnabled = true;
    textCtx.imageSmoothingQuality = "high";

    if (color) {
      textCtx.fillStyle = color;
    }

    if (align) {
      textCtx.textAlign = align;
    }

    textCtx.textBaseline = "top";

    const lineHeight = size * 1.1;
    const lines = content.split("\n");

    lines.forEach((line) => {
      const metrics = textCtx.measureText(line);
      maxWidth = Math.max(metrics.width, maxWidth);
    });

    lines.forEach((line, index) => {
      const linePositionY = position.y + index * lineHeight;

      const linePositionX =
        align === "left" ? position.x : align === "right" ? position.x + maxWidth : position.x + maxWidth / 2;
      drawLine(textCtx, line, linePositionX, linePositionY, stroke, align, underline, size, color);
    });
  });

  ctx.drawImage(
    textCanvas,
    printAreaSetting.setting.offset.x,
    printAreaSetting.setting.offset.y,
    printAreaSetting.setting.size.width,
    printAreaSetting.setting.size.height
  );
};

const drawLine = (ctx, line, x, y, stroke, align, underline, size, color) => {
  ctx.fillText(line, x, y);

  if (stroke && stroke.width > 0) {
    ctx.strokeStyle = stroke.color;
    ctx.lineWidth = stroke.width;
    ctx.strokeText(line, x, y);
    ctx.fillText(line, x, y);
  }

  if (underline) {
    const textWidth = ctx.measureText(line).width;

    let underlineStartX;
    if (align === "left") {
      underlineStartX = x;
    } else if (align === "right") {
      underlineStartX = x - textWidth;
    } else {
      underlineStartX = x - textWidth / 2;
    }

    const underlineEndX = underlineStartX + textWidth;

    const underlineY = y + size * 1.1;

    ctx.beginPath();
    ctx.moveTo(underlineStartX, underlineY);
    ctx.lineWidth = 5;
    if (stroke && stroke.width > 0) {
      ctx.strokeStyle = stroke.color;
    } else {
      ctx.strokeStyle = color;
    }
    ctx.lineTo(underlineEndX, underlineY);
    ctx.stroke();
  }
};

const drawImage = (ctx, printAreaSetting, image, imgFile) => {
  const { offset = { x: 0, y: 0 }, scale = 1, width, height } = image;

  const x = printAreaSetting.setting.offset.x;
  const y = printAreaSetting.setting.offset.y;
  const outputSizeWidth = printAreaSetting.setting.size.width;
  const outputSizeHeight = printAreaSetting.setting.size.height;
  const _scale = scale.toFixed(2) / 2;

  const imgAspectRatio = width / height;
  const outputAspectRatio = outputSizeWidth / outputSizeHeight;

  let actualSx, actualSy, cropWidth, cropHeight;

  if (imgAspectRatio > outputAspectRatio) {
    cropHeight = height / _scale;
    cropWidth = cropHeight * outputAspectRatio;
    actualSx = (offset.x / _scale) * -1;
    actualSy = (offset.y / _scale) * -1;
  } else {
    cropWidth = width / _scale;
    cropHeight = cropWidth / outputAspectRatio;
    actualSx = (offset.x / _scale) * -1;
    actualSy = (offset.y / _scale) * -1;
  }

  actualSx = actualSx.toFixed(2);
  actualSy = actualSy.toFixed(2);

  ctx.filter = `contrast(100%) brightness(90%) saturate(120%)`;
  // ctx.drawImage(imgFile, actualSx, actualSy, cropWidth, cropHeight, x, y, outputSizeWidth, outputSizeHeight);

  drawImageWithCrop(ctx, imgFile, actualSx, actualSy, cropWidth, cropHeight, x, y, outputSizeWidth, outputSizeHeight);

  ctx.filter = "none";
};

function drawImageWithCrop(ctx, img, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight) {
  const tempCanvas = document.createElement("canvas");
  tempCanvas.width = sWidth;
  tempCanvas.height = sHeight;
  const tempCtx = tempCanvas.getContext("2d");

  tempCtx.drawImage(img, -sx, -sy);

  ctx.drawImage(tempCanvas, 0, 0, sWidth, sHeight, dx, dy, dWidth, dHeight);
}
