import ReactDOM from "react-dom/client";
import App from "./App";
import ErrorBoundary from "@/components/ErrorBoundary";
import TextureUpdateProvider from "@/context/TextureUpdateProvider";

import "./index.css";
import { store } from "./store/store";
import { Provider } from "react-redux";

const rootElement = document.getElementById("gmc-root");
const lang = rootElement.getAttribute("data-lang");
const formEndpoint = rootElement.getAttribute("data-form-endpoint");
const buyEndpoint = rootElement.getAttribute("data-buy-endpoint");

ReactDOM.createRoot(rootElement).render(
  <Provider store={store}>
    <TextureUpdateProvider>
      <ErrorBoundary>
        <App lang={lang} formEndpoint={formEndpoint} buyEndpoint={buyEndpoint} />
      </ErrorBoundary>
    </TextureUpdateProvider>
  </Provider>
);
