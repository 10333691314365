import { createSlice } from "@reduxjs/toolkit";
import baublesList from "const/baublesList";

const initialState = {
  path:
    `${import.meta.env.VITE_DIR}/models/maps/${JSON.parse(localStorage.getItem("appSettings")).filename}.jpg` ||
    `${import.meta.env.VITE_DIR}/models/maps/${baublesList[0].filename}.jpg`,
};

const textureImageSlice = createSlice({
  name: "textureImage",
  initialState: initialState,
  reducers: {
    setTextureImage: (state, action) => {
      state.textureImage = action.payload;
    },
  },
});

export const { setTextureImage } = textureImageSlice.actions;

export default textureImageSlice.reducer;
