import { useState, useLayoutEffect, useRef } from "react";
import { Suspense } from "react";
import SideNav from "components/SideNav";
import Canvas3D from "components/Canvas3D";
import { Modal } from "components/Modal";
import InvisibleCanvas from "components/InvisibleCanvas";
import { OfferRequest } from "components/OfferRequest";
import { ChristmasTreeView } from "components/ChristmasTreeView";
import { useDispatch } from "react-redux";
import { setSettings } from "store/settingsSlice";
import Alert from "components/Alert.jsx";

function App(props) {
  const { lang, formEndpoint, buyEndpoint } = props;
  const canvasRef = useRef(document.createElement("canvas"));

  const [offerModal, setOfferModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showTree, setShowTree] = useState(false);
  const [alert, setAlert] = useState({
    type: "none",
    open: false,
  });

  const dispatch = useDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("id");

  useLayoutEffect(() => {
    if (!id) return;
    (async () => {
      const initialState = await fetchConfig(id);
      if (initialState) {
        dispatch(setSettings(initialState));
      }
    })();
  }, [id]);

  const handleShowTree = () => {
    setShowTree((prevState) => !prevState);
  };

  return (
    <main className="flex flex-row h-screen flex-wrap sm:flex-nowrap border-[#E0E0E0] border rounded-sm border-solid md:overflow-y-hidden">
      <Suspense fallback={null}>
        <Canvas3D lang={lang} handleShowTree={handleShowTree} showTree={showTree} canvasRef={canvasRef} />
        {!showTree ? null : (
          <ChristmasTreeView lang={lang} handleShowTree={handleShowTree} showTree={showTree} canvasRef={canvasRef} />
        )}
      </Suspense>

      <SideNav
        setShowModal={setShowModal}
        setOfferModal={setOfferModal}
        setShowTree={setShowTree}
        lang={lang}
        buyEndpoint={buyEndpoint}
      />

      <Modal lang={lang} showModal={showModal} setShowModal={setShowModal} />
      <InvisibleCanvas canvasRef={canvasRef} />
      {offerModal && (
        <OfferRequest
          lang={lang}
          setAlert={setAlert}
          alert={alert}
          setOfferModal={setOfferModal}
          endpoint={formEndpoint}
        />
      )}
      {alert.open && <Alert type={alert.type} lang={lang} setAlert={setAlert} alert={alert} />}
    </main>
  );
}

export default App;

async function fetchConfig(id) {
  if (!id) return null;

  try {
    const response = await fetch("/?wc-ajax=wc_ajax_gmc_get_config&id=" + id);
    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }

    const dataString = await response.json();
    if (!dataString) {
      throw new Error("No data");
    }

    const data = dataString;
    return data || null;
  } catch (error) {
    console.error("Error:", error);
    return null;
  }
}
