import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
const canvas = document.createElement("canvas");

export const useExportBase64Image = () => {
  const [isLoading, setIsLoading] = useState(false);
  const bauble = useSelector((state) => state.bauble.bauble);
  const settings = useSelector((state) => state.settings.settings);

  const generateBase64Image = useCallback(async () => {
    canvas.width = settings.canvasSize.width;
    canvas.height = settings.canvasSize.height;
    const ctx = canvas.getContext("2d");
    ctx.setTransform(1, 0, 0, 1, 0, 0);
    ctx.clearRect(0, 0, settings.canvasSize.width, settings.canvasSize.height);

    try {
      const printAreaSetting = settings.printAreas[0];
      if (!printAreaSetting) {
        console.error("No print area setting found");
        return null;
      }

      const imageSrc = await createImageSrc(ctx, printAreaSetting);

      if (!imageSrc) {
        console.error("Failed to create image source");
        return null;
      }

      return imageSrc;
    } catch (error) {
      console.error("Error in generateBase64Image:", error);
      return null;
    }
  }, [settings, bauble]);

  const getImageBase64 = useCallback(async () => {
    try {
      setIsLoading(true);
      const path = await generateBase64Image();
      setIsLoading(false);
      return path;
    } catch (error) {
      console.error("Error generating image:", error);
      setIsLoading(false);
      return null;
    }
  }, [generateBase64Image]);

  return { getImageBase64, isLoading };
};

export const createImageSrc = async (ctx, printAreaSetting) => {
  if (!printAreaSetting) {
    return null;
  }

  await drawImageAsync(ctx, printAreaSetting);

  if (printAreaSetting.texts?.length > 0) {
    drawTexts(ctx, printAreaSetting);
  }

  const croppedCanvas = cropCanvas(canvas, printAreaSetting);

  return croppedCanvas.toDataURL("image/png");
};

const cropCanvas = (canvas, printAreaSetting) => {
  const positionX = printAreaSetting.setting.offset.x;
  const positionY = printAreaSetting.setting.offset.y;
  const width = printAreaSetting.setting.size.width;
  const height = printAreaSetting.setting.size.height;

  const croppedCanvas = document.createElement("canvas");
  croppedCanvas.width = width;
  croppedCanvas.height = height;
  const croppedCtx = croppedCanvas.getContext("2d");
  croppedCtx.clearRect(0, 0, width, height);
  croppedCtx.drawImage(canvas, positionX, positionY, width, height, 0, 0, width, height);

  return croppedCanvas;
};

const drawImageAsync = (ctx, printAreaSetting) => {
  return new Promise((resolve, reject) => {
    const { image } = printAreaSetting;

    if (image?.src) {
      const { offset = { x: 0, y: 0 }, scale = 1, width, height } = image;
      const img = new Image();

      // Pozycja, gdzie obrazek ma być wstawiony
      const x = printAreaSetting.setting.offset.x;
      const y = printAreaSetting.setting.offset.y;
      const outputSizeWidth = printAreaSetting.setting.size.width;
      const outputSizeHeight = printAreaSetting.setting.size.height;
      const _scale = scale / 2;

      const imgAspectRatio = width / height;
      const outputAspectRatio = outputSizeWidth / outputSizeHeight;

      let actualSx, actualSy, cropWidth, cropHeight;

      if (imgAspectRatio > outputAspectRatio) {
        cropHeight = height / _scale;
        cropWidth = cropHeight * outputAspectRatio;
        // actualSx = Math.max((width - cropWidth) / 2, 0);
        actualSx = (offset.x / _scale) * -1;
        actualSy = (offset.y / _scale) * -1;
      } else {
        cropWidth = width / _scale;
        cropHeight = cropWidth / outputAspectRatio;
        actualSx = (offset.x / _scale) * -1;
        // actualSy = Math.max((height - cropHeight) / 2, 0);
        actualSy = (offset.y / _scale) * -1;
      }

      img.onload = () => {
        try {
          // ctx.drawImage(img, actualSx, actualSy, cropWidth, cropHeight, x, y, outputSizeWidth, outputSizeHeight);
          drawImageWithCrop(
            ctx,
            img,
            actualSx,
            actualSy,
            cropWidth,
            cropHeight,
            x,
            y,
            outputSizeWidth,
            outputSizeHeight
          );
          resolve();
        } catch (error) {
          reject(error);
        }
      };

      img.onerror = (e) => {
        reject(new Error(`Failed to load image: ${e.message}`));
      };

      img.src = image.src;
    } else {
      resolve();
    }
  });
};

const drawTexts = (ctx, printAreaSetting) => {
  const textCanvas = document.createElement("canvas");
  textCanvas.width = printAreaSetting.setting.size.width;
  textCanvas.height = printAreaSetting.setting.size.height;
  const textCtx = textCanvas.getContext("2d");

  const { texts } = printAreaSetting;

  texts.forEach((text) => {
    const { position, content, size, font, color, align, bold, italic, stroke, underline } = text;
    let maxWidth = 0;
    let fontStyle = `${italic ? "italic" : ""} ${bold ? "bold" : ""}`;
    textCtx.font = `${fontStyle} ${size}px ${font}`;
    textCtx.fillStyle = color;
    textCtx.textAlign = align;
    textCtx.textBaseline = "top";

    const lineHeight = size * 1.1;
    const lines = content.split("\n");

    lines.forEach((line) => {
      const metrics = textCtx.measureText(line);
      maxWidth = Math.max(metrics.width, maxWidth);
    });

    lines.forEach((line, index) => {
      const linePositionY = position.y + index * lineHeight;

      const linePositionX =
        align === "left" ? position.x : align === "right" ? position.x + maxWidth : position.x + maxWidth / 2;
      drawLine(textCtx, line, linePositionX, linePositionY, stroke, align, underline, size, color);
    });
  });

  ctx.drawImage(
    textCanvas,
    printAreaSetting.setting.offset.x,
    printAreaSetting.setting.offset.y,
    printAreaSetting.setting.size.width,
    printAreaSetting.setting.size.height
  );
};

const drawLine = (ctx, line, x, y, stroke, align, underline, size, color) => {
  ctx.fillText(line, x, y);

  if (stroke && stroke.width > 0) {
    ctx.strokeStyle = stroke.color;
    ctx.lineWidth = stroke.width;
    ctx.strokeText(line, x, y);
    ctx.fillText(line, x, y);
  }

  if (underline) {
    const textWidth = ctx.measureText(line).width;

    let underlineStartX;
    if (align === "left") {
      underlineStartX = x;
    } else if (align === "right") {
      underlineStartX = x - textWidth;
    } else {
      underlineStartX = x - textWidth / 2;
    }

    const underlineEndX = underlineStartX + textWidth;

    const underlineY = y + size * 1.1;

    ctx.beginPath();
    ctx.moveTo(underlineStartX, underlineY);
    ctx.lineWidth = 5;
    if (stroke && stroke.width > 0) {
      ctx.strokeStyle = stroke.color;
    } else {
      ctx.strokeStyle = color;
    }
    ctx.lineTo(underlineEndX, underlineY);
    ctx.stroke();
  }
};

function drawImageWithCrop(ctx, img, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight) {
  const tempCanvas = document.createElement("canvas");
  tempCanvas.width = sWidth;
  tempCanvas.height = sHeight;
  const tempCtx = tempCanvas.getContext("2d");

  tempCtx.drawImage(img, -sx, -sy);

  ctx.drawImage(tempCanvas, 0, 0, sWidth, sHeight, dx, dy, dWidth, dHeight);
}
