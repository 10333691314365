import { useCallback, useState } from "react";
import TextureUpdateContext from "./TextureUpdateContext";

const TextureUpdateProvider = ({ children }) => {
  const [updateFunction, setUpdateFunction] = useState(() => () => {});

  const registerUpdateFunction = useCallback((func) => {
    setUpdateFunction(() => func);
  }, []);

  return (
    <TextureUpdateContext.Provider value={{ updateTexture: updateFunction, registerUpdateFunction }}>
      {children}
    </TextureUpdateContext.Provider>
  );
};

export default TextureUpdateProvider;
