/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unknown-property */
import { Suspense, useEffect, useState, useRef, useCallback, useContext } from "react";
import { Canvas } from "@react-three/fiber";
import { ContactShadows, Environment, OrbitControls } from "@react-three/drei";
import TextureUpdateContext from "@/context/TextureUpdateContext";

import { CameraControls } from "@react-three/drei";
import CameraUI from "components/CameraUI";
import ModelLoader from "./ModelLoader";
import { useLoader } from "@react-three/fiber";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader";

import * as THREE from "three";
import ViewOnTreeButton from "./ViewOnTreeButton";
import { useSelector } from "react-redux";
import cameraSettingsDef from "@/const/cameraSettings";
import { debounce, throttle } from "lodash";

const Model = ({ filename, canvasRef }) => {
  const meshRef = useRef();
  const textureHiRef = useRef(null);
  const textureLoRef = useRef(null);
  const { registerUpdateFunction } = useContext(TextureUpdateContext);

  const materials = useLoader(MTLLoader, `${import.meta.env.VITE_DIR}/models/${filename}.mtl`);
  const obj = useLoader(OBJLoader, `${import.meta.env.VITE_DIR}/models/${filename}.obj`, (loader) => {
    materials.preload();
    loader.setMaterials(materials);
  });

  const updateCanvasTexture = (canvas, isHighQuality = true) => {
    let textureHi = textureHiRef.current;
    let textureLo = textureLoRef.current;

    if (isHighQuality) {
      if (!textureHi) {
        textureHi = new THREE.CanvasTexture(canvas);
        textureHi.colorSpace = THREE.SRGBColorSpace;
        textureHiRef.current = textureHi;
      } else {
        textureHi.image = canvas;
        textureHi.needsUpdate = true;
      }
    } else {
      if (!textureLo) {
        textureLo = new THREE.CanvasTexture(canvas);
        textureLo.colorSpace = THREE.SRGBColorSpace;
        textureLoRef.current = textureLo;
      } else {
        textureLo.image = canvas;
        textureLo.needsUpdate = true;
      }
    }

    if (isHighQuality) {
      textureHi.minFilter = THREE.LinearFilter;
      textureHi.magFilter = THREE.LinearFilter;
    }

    const mesh = meshRef.current;
    if (mesh) {
      const texture = isHighQuality ? textureHi : textureLo;
      mesh.material.map = texture;
      mesh.material.needsUpdate = true;
    }
  };

  const updateTextureLowQuality = useCallback(
    throttle(() => {
      const canvas = canvasRef.current;
      const copyCanvas = document.createElement("canvas");
      copyCanvas.width = canvas.width;
      copyCanvas.height = canvas.height;
      const copyCtx = copyCanvas.getContext("2d");
      copyCtx.filter = "blur(5px)";
      copyCtx.drawImage(canvasRef.current, 0, 0);

      const smallCanvas = document.createElement("canvas");
      smallCanvas.width = canvas.width / 10;
      smallCanvas.height = canvas.height / 10;
      const smallCtx = smallCanvas.getContext("2d");

      smallCtx.drawImage(
        copyCanvas,
        0,
        0,
        copyCanvas.width,
        copyCanvas.height,
        0,
        0,
        smallCanvas.width,
        smallCanvas.height
      );

      updateCanvasTexture(smallCanvas, false);
    }, 100),
    []
  );

  const updateTextureHighQuality = useCallback(
    debounce(() => {
      const canvas = canvasRef.current;
      updateCanvasTexture(canvas);
    }, 200),
    []
  );

  useEffect(() => {
    const updateTexture = () => {
      updateTextureLowQuality();
      updateTextureHighQuality();
    };
    registerUpdateFunction(updateTexture);
  }, [registerUpdateFunction]);

  useEffect(() => {
    textureHiRef.current = null;
    textureLoRef.current = null;
    updateTextureLowQuality();
    updateTextureHighQuality();
  }, [obj, updateTextureHighQuality, updateTextureLowQuality]);

  return (
    <group>
      <mesh
        ref={meshRef}
        position={[0, 0, 0]}
        rotation={[0, 0, 0]}
        scale={[0.05, 0.05, 0.05]}
        castShadow
        receiveShadow
        geometry={obj.children[0].geometry}
        material={obj.children[0].material}
      />
    </group>
  );
};

const Lights = () => {
  const light = useRef();

  return (
    <group>
      <spotLight ref={light} angle={0.1} position={[0, 30, -60]} castShadow intensity={200} />
      <ambientLight intensity={0.5} />
    </group>
  );
};

const Canvas3D = ({ lang, handleShowTree, showTree, canvasRef }) => {
  const cameraControlRef = useRef(null);
  const filename = useSelector((state) => state.settings.settings?.filename);
  const [currentPosition, setCurrentPosition] = useState(null);

  useEffect(() => {
    setCurrentPosition(cameraSettingsDef[filename][0].position);
  }, [filename]);

  useEffect(() => {
    if (cameraControlRef.current) {
      cameraControlRef.current?.setPosition(...currentPosition, true);
    }
  }, [currentPosition, cameraControlRef]);

  return (
    <div className="w-full h-1/2 md:h-screen relative flex flex-col" style={{ display: showTree ? "none" : "flex" }}>
      <CameraUI lang={lang} setCameraPosition={setCurrentPosition} />
      <ViewOnTreeButton handleShowTree={handleShowTree} lang={lang} />
      <Canvas
        shadows
        className="h-full w-full"
        onCreated={(state) => {
          state.camera.position.set(...currentPosition);
        }}
      >
        <Suspense fallback={<ModelLoader />}>
          <Lights />
          <Model filename={filename} canvasRef={canvasRef} />
          <CameraControls ref={cameraControlRef} />
          <Environment preset="studio" />
          <ContactShadows frames={1} scale={6} position={[0, -3, 0]} far={3} blur={5} opacity={1} color="#204080" />
          <ContactShadows
            rotation-x={Math.PI / 2}
            position={[0, -0.8, 0]}
            opacity={0.25}
            width={10}
            height={10}
            blur={1.5}
            far={0.8}
          />
          <OrbitControls enableZoom enablePan={true} enableRotate={true} dampingFactor={0.1} />
        </Suspense>
      </Canvas>
    </div>
  );
};

export default Canvas3D;
