/* eslint-disable react/no-unknown-property */
import { Canvas, useLoader } from "@react-three/fiber";
import { Suspense } from "react";

import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import translations from "const/translations.json";
import * as THREE from "three";
import ModelLoader from "./ModelLoader";

import { OrbitControls } from "@react-three/drei";
import { MeshPhongMaterial } from "three";
import { useSelector } from "react-redux";

const Model = ({ obj, position, scale, rotation }) => {
  //eslint-disable-next-line
  if (!position) {
    position = [0, 0, 0];
  }

  if (!scale) {
    scale = [0.05, 0.05, 0.05];
  }

  if (!rotation) {
    rotation = [0, 0, 0];
  }
  // eslint-disable-next-line react/no-unknown-property
  return <primitive object={obj} scale={scale} position={position} rotation={rotation} />;
};

const ModelTree = () => {
  let distDir = import.meta.env.VITE_DIR ? import.meta.env.VITE_DIR : "";
  const loadedFbx = useLoader(FBXLoader, `${distDir}/tree.fbx`);

  loadedFbx.traverse((child) => {
    if (child.isMesh) {
      if (child.name === "leaves") {
        child.material = new MeshPhongMaterial({
          color: 0x00b000,
          opacity: 1,
        });
      }
      if (child.name === "tree") {
        child.material = new MeshPhongMaterial({ color: 0x8b4513 });
      }
    }
  });

  // eslint-disable-next-line react/no-unknown-property
  return <primitive object={loadedFbx} position={[0, -2, 0]} scale={[0.01, 0.01, 0.01]} rotation={[0, 0, 0]} />;
};

const Lights = () => {
  return (
    <group>
      <spotLight angle={0.1} position={[0, 30, -80]} castShadow intensity={100} />
      <ambientLight intensity={0.6} />
    </group>
  );
};

export const ChristmasTreeView = ({ handleShowTree, lang, showTree, canvasRef }) => {
  const settings = useSelector((state) => state.settings.settings);

  return (
    <div
      className={`${
        showTree ? "w-full  flex flex-col h-1/2 md:h-screen opacity-1 relative" : "opacity-0 absolute"
      } ease-out duration-300`}
      // className="w-full h-[200px] relative flex flex-col sm:h-[700px]"
      // style={{ display: showTree ? "flex" : "none" }}
    >
      <Canvas
        className="h-full w-full"
        onCreated={({ camera }) => {
          camera.position.set(4, 0, 0);
        }}
      >
        <Suspense fallback={<ModelLoader />}>
          <Lights />
          <ModelTree />
          <Bulbs settings={settings} canvasRef={canvasRef} />

          <OrbitControls enableZoom enablePan={false} enableRotate={true} enableDamping />
        </Suspense>
      </Canvas>
      <button onClick={() => handleShowTree()} className="absolute top-2 right-2 p-2 bg-red-500 rounded-md text-white">
        {translations[lang].back}
      </button>
    </div>
  );
};

const Bulbs = ({ settings, canvasRef }) => {
  const materials = useLoader(MTLLoader, `${import.meta.env.VITE_DIR}/models/${settings?.filename}.mtl`);
  const obj = useLoader(OBJLoader, `${import.meta.env.VITE_DIR}/models/${settings?.filename}.obj`, (loader) => {
    materials.preload();
    loader.setMaterials(materials);
  });

  const bulbTexture = new THREE.CanvasTexture(canvasRef.current);

  obj.traverse((child) => {
    if (child.isMesh) {
      bulbTexture.wrapS = THREE.RepeatWrapping;
      bulbTexture.wrapT = THREE.RepeatWrapping;
      bulbTexture.repeat.set(1, 1);
      bulbTexture.offset.set(0, 0);
      bulbTexture.encoding = THREE.sRGBEncoding;
      child.material.map = bulbTexture;
    }
  });

  const settingsBulbs = [
    {
      radius: 0.4,
      bulbCount: 3,
      rotation: 1.5,
      positionY: 1.7,
    },
    {
      radius: 0.55,
      bulbCount: 4,
      rotation: 1.5,
      positionY: 1.2,
    },
    {
      radius: 0.7,
      bulbCount: 4,
      rotation: 1.5,
      positionY: 0.8,
    },
    {
      radius: 0.7,
      bulbCount: 5,
      rotation: 1.5,
      positionY: 0.4,
    },
    {
      radius: 0.9,
      bulbCount: 6,
      rotation: 1.5,
      positionY: -0.2,
    },
    {
      radius: 1.1,
      bulbCount: 8,
      rotation: 1.5,
      positionY: -0.9,
    },
  ];

  const bulbsData = generateBulbData(settingsBulbs);

  if (!bulbsData) {
    return null;
  }

  return bulbsData.map((bulb, index) => {
    return (
      <Model
        key={index}
        obj={obj.clone()}
        position={bulb.position}
        scale={[0.004, 0.004, 0.004]}
        rotation={bulb.rotation}
      />
    );
  });
};
const generateBulbData = (settings) => {
  let bulbsData = [];

  settings.forEach((setting) => {
    const { radius, bulbCount, rotation, positionY } = setting;

    const rowRotationOffset = (Math.random() - 0.5) * 0.4;

    const startAngleOffset = (Math.random() * Math.PI * 2) / bulbCount;

    for (let i = 0; i < bulbCount; i++) {
      const angle = (i / bulbCount) * Math.PI * 2 + Math.PI / 2 + startAngleOffset + (Math.random() - 0.5) * 0.8;

      const randomOffset = () => (Math.random() - 0.5) * 0.1;

      const x = radius * Math.cos(angle) + randomOffset();
      const z = radius * Math.sin(angle) + randomOffset();
      const yPosition = positionY + randomOffset();

      const rotationY = -angle + Math.PI + rotation + rowRotationOffset;

      bulbsData.push({
        position: [x, yPosition, z],
        scale: [0.004, 0.004, 0.004],
        rotation: [0, rotationY, 0],
      });
    }
  });

  return bulbsData;
};
