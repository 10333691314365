import BaubleColor from "components/BaubleColor";

import Divider from "components/Divider";
import SelectBauble from "components/Inputs/SelectBauble";

const BaubleSettings = ({ lang }) => {
  return (
    <div className="flex flex-col gap-[16px] w-full pb-8">
      <SelectBauble lang={lang} />
      <Divider />
      <BaubleColor lang={lang} />
    </div>
  );
};

export default BaubleSettings;
