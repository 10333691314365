import translations from "const/translations.json";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useExportBase64Image } from "hooks/useExportBase64Image";

export const ButtonsGroup = ({ lang, setOfferModal, buyEndpoint }) => {
  const settings = useSelector((state) => state.settings.settings);
  const { getImageBase64 } = useExportBase64Image();
  const [sending, setSending] = useState(false);

  const handleBuy = async (e) => {
    e.preventDefault();
    setSending(true);
    const imageBase64 = await getImageBase64();

    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id");
    const url = id ? `${buyEndpoint}&id=${id}` : buyEndpoint;
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ settings: settings, imageBase64: imageBase64 }),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          setSending(false);
          console.error(response);
        }
      })
      .then((data) => {
        const { link } = data;
        if (link) {
            window.location.href = link;
        }
      })
      .catch((error) => {
        setSending(false);
        console.error("Error:", error);
      });
  };

  return (
    <div className="sm:mb-[20px] mt-[10px] px-8 w-full flex md:flex-row flex-col md:gap-[8px] gap-[8px] ">
      <button
        className="rounded-[5px] ease duration-300 w-full py-[10px] bg-[#F2F2F2] hover:bg-[#2EA3F2] text-black hover:text-white disabled:opacity-50"
        onClick={handleBuy}
        disabled={sending}
      >
        {translations[lang].addToCart}
      </button>
      <button
        onClick={() => {
          setOfferModal(true);
        }}
        className="rounded-[5px] ease duration-300 w-full py-[10px] bg-[#F2F2F2] hover:bg-[#2EA3F2] text-black hover:text-white"
      >
        {translations[lang].offerRequest}
      </button>
    </div>
  );
};
