import { Html } from "@react-three/drei";

const ModelLoader = () => {
  return (
    <Html center className="text-black text-2xl">
      <div className="flex flex-col items-center justify-center">
        <p className="text-2xl whitespace-nowrap">Loading...</p>
        <p className="text-2xl whitespace-nowrap">Please wait</p>
      </div>
    </Html>
  );
};

export default ModelLoader;
