import { configureStore } from "@reduxjs/toolkit";
import settingsReducer from "./settingsSlice";
import printAreaReducer from "./printAreaSlice";
import baubleReducer from "./baubleSlice";
import textIdReducer from "./textIdSlice";
import downloadLinkReducer from "./downloadLinkSlice";
import objReducer from "./objSlice";
import textureImageReducer from "./textureImageSlice";

export const store = configureStore({
  reducer: {
    settings: settingsReducer,
    printArea: printAreaReducer,
    bauble: baubleReducer,
    textId: textIdReducer,
    downloadLink: downloadLinkReducer,
    obj: objReducer,
    textureImage: textureImageReducer,
  },
  devTools: false,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});
