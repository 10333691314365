import { useSelector } from "react-redux";
import translations from "const/translations.json";
import { useExportBase64Image } from "hooks/useExportBase64Image";

const ExportTextures = ({ lang }) => {
  const bauble = useSelector((state) => state.bauble.bauble);
  const settings = useSelector((state) => state.settings.settings);

  const canvas = document.createElement("canvas");
  const { getImageBase64, isLoading } = useExportBase64Image(canvas, settings, bauble);

  const downloadImage = (url, filename) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownload = async () => {
    const imageBase64 = await getImageBase64();
    if (imageBase64) {
      downloadImage(imageBase64, `${bauble.name}-obszar-1.png`);
    }
  };

  return (
    <>
      <button
        disabled={isLoading}
        className="w-full bg-[#2EA3F2] py-[10px] sm:py-[15px] rounded-[5px] text-white flex items-center justify-center gap-2"
        onClick={handleDownload}
      >
        {isLoading ? (
          <span>Generuje pliki...</span>
        ) : (
          <>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M10 2V13M10 13L14.5 8.5M10 13L5.5 8.5" stroke="#FCFDFF" />
              <path d="M3 14V17H17V14" stroke="#FCFDFF" />
            </svg>
            <span>{translations[lang].export}</span>
          </>
        )}
      </button>
    </>
  );
};

export default ExportTextures;
