import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import baublesList from "const/baublesList";
import { setPrintArea } from "./printAreaSlice";

const initialState = {
  bauble: JSON.parse(localStorage.getItem("bauble")) || {
    id: baublesList[0].id,
    name: baublesList[0].name,
  },
};

if (!localStorage.getItem("bauble")) {
  localStorage.setItem(
    "bauble",
    JSON.stringify({
      id: baublesList[0].id,
      name: baublesList[0].name,
    })
  );
}

const baubleSlice = createSlice({
  name: "bauble",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setBauble.fulfilled, (state, action) => {
      const { bauble } = action.payload;
      state.bauble = bauble;
      localStorage.setItem("bauble", JSON.stringify(bauble));
    });
  },
});

export const setBauble = createAsyncThunk("bauble/setBauble", async (bauble, thunkAPI) => {
  const newPrintArea = baublesList.find((b) => b.id === bauble.id);
  thunkAPI.dispatch(setPrintArea({ id: newPrintArea.printAreas[0].id, name: newPrintArea.printAreas[0].name }));
  return { bauble };
});

export default baubleSlice.reducer;
